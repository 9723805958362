import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

const Navbar = ({ navbarRef, section, isMobile }) => {
  const [active, setActive] = useState(section);

  const handleScroll = () => {
    if (active === "contact") return;

    const fingerPrincessSection = document.getElementById("finger_princess");
    const fpMakerSection = document.getElementById("fp_maker");

    if (fingerPrincessSection) {
      const sectionTop = fingerPrincessSection.getBoundingClientRect().top;

      if (
        sectionTop < window.innerHeight / 2 &&
        fpMakerSection.getBoundingClientRect().top >= window.innerHeight / 2
      ) {
        setActive("pingp");
      } else if (
        fpMakerSection &&
        fpMakerSection.getBoundingClientRect().top < window.innerHeight / 2
      ) {
        setActive("pingpmaker");
      } else {
        setActive("home");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleExternalLinkClick = (e, url) => {
    e.preventDefault();
    window.location.href = url;
  };

  const handleNavLinkClick = () => {
    if (
      navbarRef.current &&
      window.getComputedStyle(navbarRef.current).display !== "none"
    ) {
      const collapseElement = document.getElementById("navbarSupportedContent");
      if (collapseElement && collapseElement.classList.contains("show")) {
        collapseElement.classList.remove("show");
      }
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light style-4"
      ref={navbarRef}
    >
      <div className="container">
        <Link
          className="navbar-brand"
          to="/"
          style={isMobile ? {} : { position: "absolute", left: "0px" }}
        >
          <img
            src="/assets/img/logo_pingp.png"
            alt=""
            style={{
              height: isMobile ? "28px" : "51px",
              paddingLeft: isMobile ? "12px" : "0px",
            }}
          />
        </Link>
        <div
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ border: "none" }}
        >
          <span className="navbar-toggler-icon"></span>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
            <li className="nav-item dropdown">
              <Link
                to="/"
                className={active === "home" ? "nav-link active" : "nav-link"}
                onClick={handleNavLinkClick}
              >
                홈
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/#finger_princess"
                className={active === "pingp" ? "nav-link active" : "nav-link"}
                onClick={handleNavLinkClick}
              >
                핑프
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/#fp_maker"
                className={
                  active === "pingpmaker" ? "nav-link active" : "nav-link"
                }
                onClick={handleNavLinkClick}
              >
                핑프메이커
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/price"
                className={active === "price" ? "nav-link active" : "nav-link"}
                onClick={handleNavLinkClick}
              >
                요금제
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/contact"
                className={
                  active === "contact" ? "nav-link active" : "nav-link"
                }
                onClick={handleNavLinkClick}
              >
                문의하기
              </Link>
            </li>
          </ul>
          <div
            className="nav-side"
            style={isMobile ? {} : { position: "absolute", right: "0px" }}
          >
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <Link
                href="https://admin.finger-princess.com/"
                className="btn rounded-pill brd-gray hover-pink sm-butn fw-bold"
                target="_blank"
              >
                <span>핑프메이커</span>
              </Link>
              <Link
                href="https://finger-princess.shop/"
                className="btn rounded-pill brd-gray hover-pink sm-butn fw-bold"
                target="_blank"
              >
                <span>💝 핑프몰 💝</span>
              </Link>
              <Link
                href="/fpmakersignup/index.html"
                className="btn rounded-pill brd-gray hover-pink sm-butn fw-bold"
              >
                <span>
                  샵 등록하기 <i className="bi bi-arrow-right ms-1"></i>{" "}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
