import React, { useEffect, useRef, useState } from "react";
import navbarScrollEffect from "common/navbarScrollEffect";
import MainLayout from "layouts/Main";
import Navbar from "components/Navbars/AppNav";
import Footer from "components/App/Footer";
import LoadingModal from "components/fpmakerSignUp/LoadingModal";
import { FaCheckCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";

const accordionData = [
  {
    title: "예약관리",
    items: [
      "모바일, PC 모드 가능",
      "직원별 예약 관리 가능",
      "캘린더 & 예약 일정 관리",
      "OFF 설정",
    ],
  },
  {
    title: "매출관리",
    items: [
      "매출 등록 알림",
      "오늘의 현황",
      "우리샵 매출",
      "고객별 매출",
      "직원별 매출",
    ],
  },
  {
    title: "알림톡 / 푸시알림",
    items: ["자동 푸시알림 지원", "예약 요청, 취소, 시술 1일전, 당일"],
  },
  {
    title: "직원관리",
    items: ["직원 등록/ 관리 (10명 까지 가능)", "직원별 일정 관리"],
  },
  {
    title: "실시간 채팅",
    items: ["핑프 , 핑프메이커 앱 내 채팅 기능"],
  },
  {
    title: "정액권 관리",
    items: ["정액권 충전/차감 관리"],
  },
  {
    title: "고객관리",
    items: ["단골 고객 관리", "고객 차트", "고객별 이용내역", "고객 프로필"],
  },
  {
    title: "핑프몰",
    items: ["트렌디한 파츠, 네일 재료", "도매가 구매 가능", "마일리지 활용"],
  },
];

const faqData = [
  {
    question: "가입 절차가 어떻게 되나요?",
    answer:
      "샵 등록하기를 통해 가입신청서를 보내주시면 담당자가 안내 (전화 및 문자)를 드립니다. 이용 가능한 링크와 계정을 통해 로그인 후 결제를 진행해주시면 바로 핑프 앱 노출과 핑프메이커 사용이 가능합니다.",
  },
  {
    question:
      "결제수단에 문제가 생길 경우 어떻게 하나요? (ex. 신용카드 만료, 잔액 부족)",
    answer:
      "다음 정기권 결제 날짜 전까지 핑프메이커 - 설정 - 결제정보에서 카드를 변경해주시면 됩니다. (PC버전에서만 가능합니다.)",
  },
  {
    question: "요금제 변경은 어떻게 하나요?",
    answer:
      "이용권을 변경할 수 있는 기능은 준비중입니다. 이용권 변경이 필요한 경우에는 고객센터(02-508-5591)로 문의해주세요.",
  },
  {
    question: "취소하거나 해지는 어떻게 하나요?",
    answer:
      "이용권은 취소하기 전까지 결제가 자동 갱신됩니다. 해지할 경우 문의하기를 통해 처리 가능합니다.",
  },
  {
    question: "오픈 예정인데 가입이 가능한가요?",
    answer:
      "가입신청서에 창업 예정으로 선택 후 신청해주시면 담당자가 안내도와드립니다.",
  },
];

const Price = () => {
  const navbarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);

  const [openIndex, setOpenIndex] = useState(null);
  const [openIndex2, setOpenIndex2] = useState(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 991);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} section="" isMobile={isMobile} />
      <LoadingModal show={isLoading} />
      {isMobile ? (
        <main
          style={{
            width: "100%",
            minHeight: "100vh",
            background: "linear-gradient(to bottom, white, #FFE4E4)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "80px",
          }}
          className={"main"}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <span style={{ fontSize: "20px", paddingBottom: "24px" }}>
              홍보부터 운영까지 스마트한 올인원 솔루션
            </span>
            <h1 style={{ fontSize: "32px", marginBottom: "100px" }}>
              단일 플랜으로 모든 기능을
              <br />
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(to right,#501e9c 0%,#8169f1 30%,#8169f1 30%,#a44cee 73%,#ff847f 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                월 4,900원
              </span>
              으로 이용하세요!
            </h1>

            {/* 요금제 토글 */}
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                marginTop: "24px",
                fontSize: "20px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: isAnnual ? "#000" : "#A0A6B1",
                  position: "relative",
                }}
              >
                연간 결제
                <span
                  style={{
                    position: "absolute",
                    fontSize: "14px",
                    top: "-20px",
                    left: "8px",
                    color: "#FF5D7A",
                  }}
                >
                  28% 할인
                </span>
              </span>

              <label className="switch">
                <input
                  type="checkbox"
                  checked={!isAnnual}
                  onChange={() => setIsAnnual(!isAnnual)}
                />
                <span className="slider"></span>
              </label>

              <span
                style={{
                  fontWeight: "bold",
                  color: !isAnnual ? "#000" : "#A0A6B1",
                }}
              >
                월간 결제
              </span>
            </div>

            {/* 토글 스위치 스타일 */}
            <style>
              {`
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #FF5D7A;
      transition: 0.4s;
      border-radius: 34px;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
    input:checked + .slider:before {
      transform: translateX(26px);
    }
  `}
            </style>
          </div>

          {/* 요금 안내 카드 */}
          <div
            style={{
              width: "92%",
              background: "#fff",
              margin: "80px 0px",
              borderRadius: "32px",
              boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
              padding: "32px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAnnual && (
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#6C33BA",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "32px",
                  right: "32px",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#ffffff",
                  fontWeight: "900",
                  textAlign: "center",
                  letterSpacing: "4px",
                }}
              >
                12개월
                <br />
                선결제시
              </div>
            )}

            <h2 style={{ fontSize: "24px", marginBottom: "32px" }}>
              핑프메이커 요금 안내
            </h2>

            {isAnnual ? (
              <>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#6C727F",
                    fontWeight: "900",
                    textDecoration: "line-through",
                  }}
                >
                  6,900원
                </span>
                <span style={{ fontSize: "24px", fontWeight: "900" }}>
                  월 4,900원
                  <span
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#A0A6B1",
                    }}
                  >
                    *부가세 별도
                  </span>
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#6C727F",
                    fontWeight: "900",
                    textDecoration: "line-through",
                    visibility: "hidden",
                  }}
                >
                  6,900원
                </span>
                <span style={{ fontSize: "24px", fontWeight: "900" }}>
                  월 6,900원
                  <span
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#A0A6B1",
                    }}
                  >
                    *부가세 별도
                  </span>
                </span>
              </>
            )}

            <div
              style={{
                padding: "60px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {[
                "입점시, 네일 예약 어플 ‘핑프'를 통한 가맹점 홍보 혜택 제공!",
                "네일 재료 전문 도매몰 ‘핑프몰'에서 구매 가능한 마일리지 제공!",
                "타사 프로그램 정보 무료 이관!",
              ].map((text, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  <FaCheckCircle
                    style={{
                      color: "#FF5D7A",
                      fontSize: "18px",
                      flexShrink: 0,
                    }}
                  />
                  {text}
                </div>
              ))}
            </div>
            <div
              style={{
                width: "92%",
                height: "60px",
                background: "#272F3E",
                borderRadius: "30px",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                cursor: "pointer",
                fontWeight: "900",
                marginBottom: "60px",
                marginLeft: "4%",
              }}
              onClick={() => {
                window.open("/fpmakersignup/index.html");
              }}
            >
              샵 등록하기
            </div>
            <h3
              style={{ color: "#A0A6B1", fontSize: "24px", fontWeight: "700" }}
            >
              주요 기능
            </h3>
            <div
              style={{
                width: "100%",
                marginTop: "40px",
                boxSizing: "border-box",
                padding: "0px 10px",
              }}
            >
              {accordionData.map((section, idx) => (
                <div
                  key={idx}
                  style={{
                    borderBottom: "0px solid #e0e0e0",
                    padding: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenIndex(openIndex === idx ? null : idx)}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {section.title}
                    {openIndex === idx ? (
                      <FaChevronUp size={16} color="#000" />
                    ) : (
                      <FaChevronDown size={16} color="#000" />
                    )}
                  </div>
                  {openIndex === idx && (
                    <ul
                      style={{
                        paddingLeft: "8px",
                        marginTop: "12px",
                        backgroundColor: "#F4F5F7",
                        padding: "16px",
                        borderRadius: "12px",
                      }}
                    >
                      {section.items.map((item, i) => (
                        <li
                          key={i}
                          style={{
                            fontSize: "16px",
                            listStyle: "disc",
                            marginLeft: "16px",
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              width: "92%",
              background: "#fff",
              margin: "0px 0px",
              borderRadius: "32px",
              boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
              padding: "32px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ fontSize: "24px" }}>자주 묻는 질문</h2>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
                boxSizing: "border-box",
              }}
            >
              {faqData.map(({ question, answer }, idx) => (
                <div
                  key={idx}
                  style={{
                    borderBottom: "0px solid #e0e0e0",
                    padding: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenIndex2(openIndex === idx ? null : idx)}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {question}
                    {openIndex === idx ? (
                      <FaChevronUp size={16} color="#000" />
                    ) : (
                      <FaChevronDown size={16} color="#000" />
                    )}
                  </div>
                  {openIndex2 === idx && (
                    <div
                      style={{
                        paddingLeft: "20px",
                        marginTop: "12px",
                        backgroundColor: "#F4F5F7",
                        padding: "25px",
                        borderRadius: "20px",
                        fontSize: "16px",
                      }}
                    >
                      {answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              padding: "100px 0px",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
                marginBottom: "32px",
              }}
            >
              더 궁금한 사항이 있으신가요?
            </span>
            <div
              style={{
                width: "100%",
                height: "56px",
                backgroundColor: "#FF5D7A",
                color: "#fff",
                borderRadius: "200px",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "24px",

                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://pf.kakao.com/_KRBrG");
              }}
            >
              문의하기
            </div>
          </div>
        </main>
      ) : (
        <main
          style={{
            width: "100%",
            minHeight: "100vh",
            background: "linear-gradient(to bottom, white, #FFE4E4)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "80px",
            zoom: "0.75",
          }}
          className={"main"}
        >
          <div style={{ width: "800px", textAlign: "center" }}>
            <span style={{ fontSize: "36px", paddingBottom: "24px" }}>
              홍보부터 운영까지 스마트한 올인원 솔루션
            </span>
            <h1 style={{ fontSize: "64px", marginBottom: "100px" }}>
              단일 플랜으로 모든 기능을
              <br />
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(to right,#501e9c 0%,#8169f1 30%,#8169f1 30%,#a44cee 73%,#ff847f 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                월 4,900원
              </span>
              으로 이용하세요!
            </h1>

            {/* 요금제 토글 */}
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                marginTop: "24px",
                fontSize: "40px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: isAnnual ? "#000" : "#A0A6B1",
                  position: "relative",
                }}
              >
                연간 결제
                <span
                  style={{
                    position: "absolute",
                    fontSize: "24px",
                    top: "-28px",
                    left: "20px",
                    color: "#FF5D7A",
                  }}
                >
                  28% 할인
                </span>
              </span>

              <label className="switch">
                <input
                  type="checkbox"
                  checked={!isAnnual}
                  onChange={() => setIsAnnual(!isAnnual)}
                />
                <span className="slider"></span>
              </label>

              <span
                style={{
                  fontWeight: "bold",
                  color: !isAnnual ? "#000" : "#A0A6B1",
                }}
              >
                월간 결제
              </span>
            </div>

            {/* 토글 스위치 스타일 */}
            <style>
              {`
              .switch {
                position: relative;
                display: inline-block;
                width: 80px;
                height: 44px;
              }
              .switch input {
                opacity: 0;
                width: 0;
                height: 0;
              }
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #FF5D7A;
                transition: 0.4s;
                border-radius: 34px;
              }
              .slider:before {
                position: absolute;
                content: "";
                height: 36px;
                width: 36px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: 0.4s;
                border-radius: 50%;
              }
              input:checked + .slider:before {
                transform: translateX(36px);
              }
            `}
            </style>
          </div>

          {/* 요금 안내 카드 */}
          <div
            style={{
              width: "1200px",
              minHeight: "800px",
              background: "#fff",
              margin: "80px 0px",
              borderRadius: "62px",
              boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
              padding: "80px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isAnnual && (
              <div
                style={{
                  width: "230px",
                  height: "230px",
                  backgroundColor: "#6C33BA",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "80px",
                  right: "80px",
                  fontSize: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#ffffff",
                  fontWeight: "900",
                  textAlign: "center",
                  letterSpacing: "4px",
                }}
              >
                12개월
                <br />
                선결제시
              </div>
            )}

            <h2 style={{ fontSize: "50px", marginBottom: "80px" }}>
              핑프메이커 요금 안내
            </h2>

            {isAnnual ? (
              <>
                <span
                  style={{
                    fontSize: "40px",
                    color: "#6C727F",
                    fontWeight: "900",
                    textDecoration: "line-through",
                  }}
                >
                  6,900원
                </span>

                <span style={{ fontSize: "70px", fontWeight: "900" }}>
                  월 4,900원
                  <span
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "500",
                      fontSize: "30px",
                      color: "#A0A6B1",
                    }}
                  >
                    *부가세 별도
                  </span>
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontSize: "40px",
                    color: "#6C727F",
                    fontWeight: "900",
                    textDecoration: "line-through",
                    visibility: "hidden",
                  }}
                >
                  6,900원
                </span>

                <span style={{ fontSize: "70px", fontWeight: "900" }}>
                  월 6,900원
                  <span
                    style={{
                      paddingLeft: "20px",
                      fontWeight: "500",
                      fontSize: "30px",
                      color: "#A0A6B1",
                    }}
                  >
                    *부가세 별도
                  </span>
                </span>
              </>
            )}

            <div
              style={{
                padding: "60px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {[
                "입점시, 네일 예약 어플 ‘핑프'를 통한 가맹점 홍보 혜택 제공!",
                "네일 재료 전문 도매몰 ‘핑프몰'에서 구매 가능한 마일리지 제공!",
                "타사 프로그램 정보 무료 이관!",
              ].map((text, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    fontSize: "30px",
                  }}
                >
                  <FaCheckCircle style={{ color: "#FF5D7A" }} />
                  {text}
                </div>
              ))}
            </div>
            <div
              style={{
                width: "100%%",
                height: "143px",
                background: "#272F3E",
                borderRadius: "30px",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px",
                cursor: "pointer",
                fontWeight: "900",
                marginBottom: "152px",
              }}
              onClick={() => {
                window.open("/fpmakersignup/index.html");
              }}
            >
              샵 등록하기
            </div>
            <h3
              style={{ color: "#A0A6B1", fontSize: "30px", fontWeight: "700" }}
            >
              주요 기능
            </h3>
            <div
              style={{
                width: "100%",
                marginTop: "40px",
                boxSizing: "border-box",
                padding: "0px 48px",
              }}
            >
              {accordionData.map((section, idx) => (
                <div
                  key={idx}
                  style={{
                    borderBottom: "0px solid #e0e0e0",
                    padding: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenIndex(openIndex === idx ? null : idx)}
                >
                  <div
                    style={{
                      fontSize: "26px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {section.title}
                    {openIndex === idx ? (
                      <FaChevronUp size={20} color="#000" />
                    ) : (
                      <FaChevronDown size={20} color="#000" />
                    )}
                  </div>
                  {openIndex === idx && (
                    <ul
                      style={{
                        paddingLeft: "20px",
                        marginTop: "12px",
                        backgroundColor: "#F4F5F7",
                        padding: "25px",
                        borderRadius: "20px",
                      }}
                    >
                      {section.items.map((item, i) => (
                        <li
                          key={i}
                          style={{
                            fontSize: "24px",
                            listStyle: "disc",
                            marginLeft: "25px",
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              width: "1200px",
              minHeight: "400px",
              background: "#fff",
              margin: "80px 0px",
              borderRadius: "62px",
              boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
              padding: "80px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ fontSize: "50px" }}>자주 묻는 질문</h2>
            <div
              style={{
                width: "100%",
                marginTop: "40px",
                boxSizing: "border-box",
              }}
            >
              {faqData.map(({ question, answer }, idx) => (
                <div
                  key={idx}
                  style={{
                    borderBottom: "0px solid #e0e0e0",
                    padding: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenIndex2(openIndex === idx ? null : idx)}
                >
                  <div
                    style={{
                      fontSize: "26px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {question}
                    {openIndex === idx ? (
                      <FaChevronUp size={20} color="#000" />
                    ) : (
                      <FaChevronDown size={20} color="#000" />
                    )}
                  </div>
                  {openIndex2 === idx && (
                    <div
                      style={{
                        paddingLeft: "20px",
                        marginTop: "12px",
                        backgroundColor: "#F4F5F7",
                        padding: "25px",
                        borderRadius: "20px",
                        fontSize: "24px",
                      }}
                    >
                      {answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              padding: "100px 0px",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "40px",
                fontWeight: "700",
                marginBottom: "32px",
              }}
            >
              더 궁금한 사항이 있으신가요?
            </span>
            <div
              style={{
                width: "568px",
                height: "124px",
                backgroundColor: "#FF5D7A",
                color: "#fff",
                borderRadius: "200px",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px",

                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://pf.kakao.com/_KRBrG");
              }}
            >
              문의하기
            </div>
          </div>
        </main>
      )}
      <Footer active="" noWave={true} isMobile={isMobile} />
    </MainLayout>
  );
};

export const Head = () => (
  <>
    <title>핑프-핑거프린세스</title>
    <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
    <link rel="stylesheet" href="/assets/css/style.css" />
  </>
);

export default Price;
